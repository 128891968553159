<template>
  <div class="stageline-progress-bar">
    <div class="progress-bar-container">
      <div
        class="progress-bar"
        role="progressbar"
        :style="`width: ${ progressBarWidth }%`"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StagelineProgressBar',
  computed: {
    ...mapGetters('stageline', [
      'currentSlide',
      'filteredCurrentStageSlideList',
    ]),
    progressBarWidth() {
      const currentSlidePosition = this.filteredCurrentStageSlideList.findIndex(slide => slide.id === this.currentSlide.id)
      return currentSlidePosition / (this.filteredCurrentStageSlideList.length - 1) * 100
    },
  },
}
</script>

<style lang="scss" scoped>
div.stageline-progress-bar {

  div.progress-bar-container {
    min-height: .55em;
    margin-bottom: .55em;
    background-color: #cfcfcf;

    .progress-bar {
      height: .55em;
      background-color: #444444;
    }
  }
}
</style>
